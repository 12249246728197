<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar factura global</h1>

    <div>
      <h3 class="form-division">Información general</h3>

      <div class="invoice-add-item special-margin">
        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Periodo (Req)"
          expanded
        >
          <b-field>
            <b-select
              placeholder="Ejemplo: Mensual"
              expanded
              v-model="newGlobalInvoice.PERIODICIDAD"
            >
              <option value="">Seleccione un periodo</option>
              <option value="01">01 Diario</option>
              <option value="02">02 Semanal</option>
              <option value="03">03 Quincenal</option>
              <option value="04">04 Mensual</option>
              <option value="05">05 Bimestral</option>
            </b-select>
          </b-field>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Mes (Req)"
          expanded
        >
          <b-taginput
            v-model="newGlobalInvoice.MESES"
            :data="globalInvoicesMonths"
            :autocomplete="false"
            :allow-new="false"
            :open-on-focus="true"
            placeholder="Seleccione un mes"
            :maxtags="1"
          >
          </b-taginput>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-three"
          label-position="inside"
          label="Año (Req)"
          expanded
        >
          <b-field>
            <b-input
              placeholder="Ejemplo: 343jkd34"
              expanded
              v-model="newGlobalInvoice.ANO"
            />
          </b-field>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="Serie de almacen (opcional)"
          expanded
        >
          <b-select
            placeholder="Almacen"
            icon="warehouse"
            v-model="warehouseSerie"
            expanded
            @input="handleWarehouseSeriesSelelected()"
          >
            <option value="" disabled>Seleccione un almacén</option>
            <option
              v-for="warehouse in warehouses"
              :value="warehouse"
              :key="warehouse.CLAVE_ALMACEN"
            >
              {{ warehouse.NOMBRE_ALM }}
            </option>
          </b-select>
        </b-field>

        <b-field
          class="invoice-add-secondary-item-second"
          label-position="inside"
          label="Serie (opcional)"
          expanded
        >
          <b-field>
            <b-input
              placeholder="Ejemplo: AB"
              expanded
              v-model="newGlobalInvoice.SERIE"
            />
          </b-field>
        </b-field>
      </div>

      <h3 class="form-division">Ventas en factura global</h3>

      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container special-margin">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>

      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in salesColumns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="table-header">
        Cantidad de ventas: <b>{{ filteredSales.length }}</b>
      </div>
      <div class="table-header">
        Total en ventas seleccionadas: <b>{{ totalChecked }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="filteredSales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="globalInvoiceSales"
        :checked-rows="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
        checkable
        @check-all="handleCheckAll"
        @check="handleCheck"
      >
        <template #empty>
          <div class="has-text-centered">
            No hay ventas en la factura global
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in salesColumns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveGlobalInvoice()"
        >Agregar factura global</b-button
      >

      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { EventBus } from "@/event-bus";

export default {
  name: "AddGlobalInvoice",
  props: ["objectInformation"],
  components: {},
  data() {
    return {
      globalInvoicesMonths: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      newGlobalInvoice: {
        MESES: [
          moment().month() + 1 < 10
            ? `0${moment().month() + 1}`
            : moment().month() + 1,
        ],
        PERIODICIDAD: "01",
        ANO: moment().year(),
        SERIE: "",
      },
      warehouseSerie: "",
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      moment: moment,
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      filterWarehouses: [],
      checkedSales: [],
      checkedAll: false,
      salesColumns: this.loadColumnsFromLocalStorage(
        [
          {
            field: "CLAVE_VENTA",
            label: "Folio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFormatted",
            label: "Estatus",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusFacFormatted",
            label: "Estatus de timbrado",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "statusDeliveryFormatted",
            label: "Estatus de envio",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "posFormatted",
            label: "Punto de venta",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_CLIENTE",
            label: "Cliente",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALM",
            label: "Almacen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_VENDEDOR",
            label: "Vendedor",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "NOMBRE_ALMACEN_ORIGEN",
            label: "Origen",
            sortable: true,
            searchable: true,
            display: true,
          },
          {
            field: "formatedCreationDate",
            label: "Fecha de la venta",
            sortable: true,
            searchable: true,
            display: true,
            customSort: this.sortByDate,
          },
          {
            field: "SUBTOTAL",
            label: "Subtotal",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "IVA",
            label: "Iva total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
          {
            field: "TOTAL",
            label: "Total",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
            customSort: this.sortyByTotal,
          },
          {
            field: "RESTA_ENV_FOR",
            label: "Restante de envio",
            sortable: true,
            searchable: true,
            display: true,
            money: true,
          },
        ],
        "add-global-invoice-list-columns"
      ),
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeParetModal");
      this.closeModal();
    },
    async saveGlobalInvoice() {
      try {
        if (!this.checkCheckedSales()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la factura global revise que se tengan una o más ventas seleccionadas`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let invoiceInformation = this.transformInvoice();
        let response = await this.$store.dispatch("SAVEDAILYINVOICE", {
          invoiceInformation: invoiceInformation,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se guardó exitosamente la factura gobal`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al guardar la factura global: ${JSON.stringify(
              response
            )}`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al guardar la factura global en la base de datos: ${JSON.stringify(
            error
          )}`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async changeFilterValue() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(this.endDate).isBefore(this.startDate)) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
    handleCheckAll() {
      this.checkedAll = !this.checkedAll;
      const table = this.$refs.globalInvoiceSales;
      this.checkedSales = this.checkedAll ? table.$data.newData.slice(0) : [];
    },
    handleCheck(rows, row) {
      if (row) {
        const index = this.checkedSales
          .map((singleSale) => singleSale._id)
          .indexOf(row._id);
        if (index >= 0) {
          this.checkedSales.splice(index, 1);
        } else {
          this.checkedSales.push(row);
        }
      }
    },
    transformInvoice() {
      let currentTotal = 0;
      let currentSubtotal = 0;
      let currentIvaTotal = 0;
      let currentIepsTotal = 0;

      // Items in the invoice
      let FAC_DET = [];

      // Add sale details
      for (const [singleSaleIndex, singleSale] of this.checkedSales.entries()) {
        // Objects to know same percentage taxes total
        let ivaBases = {};
        let iepsBases = {};

        for (const singleItem of singleSale.VENTA_DET) {
          if (ivaBases[singleItem.PORC_IVA]) {
            ivaBases[singleItem.PORC_IVA]["VALOR"] += singleItem.IVA_GTO;
            ivaBases[singleItem.PORC_IVA]["IMPORTE"] += singleItem.SUB_TOT;
          } else {
            ivaBases[singleItem.PORC_IVA] = {
              VALOR: singleItem.IVA_GTO,
              IMPORTE: singleItem.SUB_TOT,
            };
          }

          if (singleItem.IEPS_GTO) {
            if (iepsBases[singleItem.PORC_IEPS]) {
              iepsBases[singleItem.PORC_IEPS]["VALOR"] += singleItem.IEPS_GTO;
              iepsBases[singleItem.PORC_IEPS]["IMPORTE"] += singleItem.SUB_TOT;
            } else {
              iepsBases[singleItem.PORC_IEPS] = {
                VALOR: singleItem.IEPS_GTO,
                IMPORTE: singleItem.SUB_TOT,
              };
            }
          }
        }

        const newInvoiceDetail = {
          CLAVE_VENTA: singleSale._id,
          RENGLON: singleSaleIndex,
          RENGLON_F: singleSaleIndex,
          CANTIDAD_PE: 1,
          EXENTO: singleSale.EXENTO,
          SUB_TOT: singleSale.SUBTOTAL,
          TOTAL: singleSale.TOTAL,
          DESCS: [],
          COSTOS: [],
          COST_GTO: singleSale.COST_GTO,
          DESC_GTO: singleSale.DESC_GTO,
          IVA_GTO: singleSale.IVA,
          IEPS_GTO: singleSale.IEPS,
          FOLIO_VENTA: singleSale.CLAVE_VENTA,
          IVA_BASES: ivaBases,
          IEPS_BASES: iepsBases,
        };
        currentTotal += Number(newInvoiceDetail.TOTAL);
        currentSubtotal += Number(newInvoiceDetail.SUB_TOT);
        currentIvaTotal += Number(newInvoiceDetail.IVA);
        currentIepsTotal += Number(newInvoiceDetail.IEPS);
        FAC_DET.push(newInvoiceDetail);
      }

      // Create global invoice object to send to the server
      let newGlobalInvoiceObject = {
        MESES: this.newGlobalInvoice.MESES,
        PERIODICIDAD: this.newGlobalInvoice.PERIODICIDAD,
        ANO: this.newGlobalInvoice.ANO,
        SERIE: this.newGlobalInvoice.SERIE,
        FECHA: moment("2022-12-06 19:00"),
        IVA: currentIvaTotal,
        SUBTOTAL: currentSubtotal,
        TOTAL: currentTotal,
        IEPS: currentIepsTotal,
        COSTOS: [],
        DESCS: [],
      };

      newGlobalInvoiceObject.FAC_DET = FAC_DET;

      return newGlobalInvoiceObject;
    },
    checkCheckedSales() {
      return this.checkedSales.length > 0;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortyByTotal(a, b, isAsc) {
      if (isAsc) {
        return a.TOTAL.localeCompare(b.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.TOTAL.localeCompare(a.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    handleWarehouseSeriesSelelected() {
      if (this.warehouseSerie.SERIE) {
        this.newGlobalInvoice.SERIE = this.warehouseSerie.SERIE;
      }
    },
  },
  computed: {
    filteredSales() {
      return this.$store.getters.SALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }
        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }
        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );
        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        } else if (saleWithProps.STATUS_FAC === "PF") {
          saleWithProps.statusFacFormatted = "Parcialmente timbrada";
        } else if (saleWithProps.STATUS_FAC === "PP") {
          saleWithProps.statusFacFormatted = "Pendiente de timbrar";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        saleWithProps.RESTA_ENV_FOR =
          saleWithProps.RESTA > 0 ? saleWithProps.RESTA : 0;

        if (saleWithProps.STATUS === "CA") {
          saleWithProps.RESTA_ENV_FOR = 0;
        }

        return saleWithProps;
      }).filter((singleSale) => {
        return (
          (singleSale.STATUS_FAC === "SF" ||
            singleSale.STATUS_FAC === "PF" ||
            singleSale.STATUS_FAC === "PP") &&
          singleSale.STATUS !== "CA"
        );
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    totalChecked() {
      return this.checkedSales
        .reduce((sum, sell) => sum + Number(sell.TOTAL), 0)
        .toLocaleString("es-MX", { style: "currency", currency: "MXN" });
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
    salesColumns: {
      handler(newColumns) {
        this.saveColumnsToLocalStorage(
          newColumns,
          "add-global-invoice-list-columns"
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
