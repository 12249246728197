/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          v-if="sales.length === 0"
          label="Primero debes agregar una o más ventas"
          multilined
        >
          <b-button type="is-primary" disabled>Agregar factura global</b-button>
        </b-tooltip>

        <b-button
          type="is-primary"
          @click="openAddGlobalInvoice()"
          v-if="sales.length > 0"
          >Agregar factura global</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de facturas globales: <b>{{ globalInvoices.length }}</b>
      </div>
      <div class="table-header">
        Total en facturas seleccionadas: <b>{{ totalChecked }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="globalInvoices"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="globalInvoiced"
        :checked-rows.sync="checkedInvoices"
        paginated
        checkable
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_FACTURA"
      >
        <template #empty>
          <div class="has-text-centered">No hay facturas globales</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button type="is-primary" @click="showSales(props.row)"
                    >Ver ventas en CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openAddCreditNote(props.row)"
                    >Agregar nota de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showCreditNoteDetails(props.row)"
                    >Ver notas de crédito</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="downloaInvoice(props.row._id)"
                    >Descargar CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS === 'CA'"
                    @click="downloaCancellationInvoice(props.row._id)"
                    >Descargar CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openSendCFDI(props.row._id)"
                    >Enviar CFDI y XML</b-button
                  >

                  <b-button type="is-primary" @click="showXML(props.row._id)"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS !== 'CA'"
                    @click="cancelInvoice(props.row._id)"
                    >Cancelar CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS === 'CA'"
                    @click="showAcuseXML(props.row._id)"
                    >Ver acuse de cancelación</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import AddGlobalInvoice from "./AddGlobalInvoice";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import DailyCFDICancellationModal from "../../Accounting/components/DailyCFDICancellationModal.vue";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import GlobalInvoiceSales from "./GlobalInvoiceSales";
import AddGlobalCreditNote from "./AddGlobalCreditNote";
import GlobalCreditNotesDetails from "./GlobalCreditNotesDetails";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "GlobalInvoicesList",
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      checkedInvoices: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_FACTURA",
          label: "Clave de la factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "creationDateFormatted",
          label: "Fecha de creación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByCreationDate,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByCreationDate,
        },
        {
          field: "MESES_FOR",
          label: "Mes o meses",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ANO",
          label: "Año",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FOR",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SUBTOTAL",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "IVA",
          label: "IVA",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "IEPS",
          label: "IEPS",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETDAILYINVOICES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          ),
          endDate: new Date(),
          warehouses: [],
        })
      );
      if (this.$store.getters.SATCFDIUSES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
      }
      if (this.$store.getters.PAYMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      }
      if (this.$store.getters.SATREGIMENES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
      }
      if (this.$store.getters.PACKAGINGS.length === 0) {
        allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
      }
      if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
      }
      allPromises.push(this.$store.dispatch("GETVEHICULES"));
      if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
        allPromises.push(
          this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION")
        );
      }
      if (this.$store.getters.TRAILERTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
      }
      if (this.$store.getters.WEIGHTUNITS.length === 0) {
        allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
      }
      if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
        allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
      }

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    openAddGlobalInvoice() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalInvoice,
        props: {},
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: [],
          })
        );

        await Promise.all(allPromises);
      }
    },
    async showSales(invoiceInformation) {
      const invoideDetails = await this.$store.dispatch(
        "GETDAILYINVOICEDETAILS",
        {
          invoiceId: invoiceInformation._id,
        }
      );

      if (invoideDetails && invoideDetails.FAC_DET) {
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: GlobalInvoiceSales,
          props: {
            salesInvoice: invoideDetails.FAC_DET,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      }
    },
    openAddCreditNote(invoiceInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddGlobalCreditNote,
        props: {
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showCreditNoteDetails(invoiceInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: GlobalCreditNotesDetails,
        props: {
          invoiceInformation: invoiceInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloaInvoice(invoiceId) {
      try {
        this.reloadInformation();
        let response = await this.$store.dispatch("GETINVOICEPDF", {
          invoiceId: invoiceId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async downloaCancellationInvoice(invoiceId) {
      try {
        let response = await this.$store.dispatch("GETCANCELLATIONINVOICEPDF", {
          invoiceId: invoiceId,
          saleId: this.saleInformation._id,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(invoiceId) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          invoiceId: invoiceId,
          action: "SENDCFDI",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showXML(invoiceId) {
      try {
        this.reloadInformation();
        let response = await this.$store.dispatch("GETINVOICEXML", {
          invoiceId: invoiceId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml de la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml de la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    cancelInvoice(invoiceId) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: DailyCFDICancellationModal,
        props: {
          invoiceId: invoiceId,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showAcuseXML(invoiceId) {
      try {
        this.reloadInformation();
        let response = await this.$store.dispatch("GETACUSEXML", {
          invoiceId: invoiceId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse de la factura`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse de la factura`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sales() {
      return this.$store.getters.SALES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }
        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }
        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );
        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_FAC || saleWithProps.STATUS_FAC === "SF") {
          saleWithProps.statusFacFormatted = "Sin timbrar";
        } else if (saleWithProps.STATUS_FAC === "TI") {
          saleWithProps.statusFacFormatted = "Timbrada";
        } else if (saleWithProps.STATUS_FAC === "CA") {
          saleWithProps.statusFacFormatted = "Cancelada";
        } else if (saleWithProps.STATUS_FAC === "FD") {
          saleWithProps.statusFacFormatted = "En factura global";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        return saleWithProps;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    globalInvoices() {
      return this.$store.getters.DAILYINVOICES.map((singleInvoice) => {
        let singleInvoiceWithProps = { ...singleInvoice };

        singleInvoiceWithProps.creationDateFormatted = moment(
          singleInvoice.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleInvoiceWithProps.saleDateFormatted = moment(
          singleInvoice.FECHA_VENTA
        ).format("DD-MM-YYYY HH:mm");

        singleInvoiceWithProps.MESES_FOR =
          singleInvoiceWithProps.MESES.toString();

        if (singleInvoiceWithProps.STATUS === "AC") {
          singleInvoiceWithProps.STATUS_FOR = "Activa";
        }

        if (singleInvoiceWithProps.STATUS === "CA") {
          singleInvoiceWithProps.STATUS_FOR = "Cancelada";
        }

        singleInvoiceWithProps.MESES_FOR =
          singleInvoiceWithProps.MESES.toString();

        singleInvoiceWithProps.NOMBRE_ALM =
          singleInvoiceWithProps.FAC_DET[0].CLAVE_VENTA.CLAVE_ALMACEN.NOMBRE_ALM;

        return singleInvoiceWithProps;
      });
    },
    totalChecked() {
      return this.checkedInvoices
        .reduce((sum, invoice) => sum + Number(invoice.TOTAL), 0)
        .toLocaleString("es-MX", { style: "currency", currency: "MXN" });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
